import * as React from 'react';
import styled, { css } from 'styled-components';
import {
   color,
   space,
   fontFamily,
   fontSize,
   lineHeight,
   borderRadius,
   shadow,
   breakpoint,
} from '@dozuki/web-js/primitives';
import PdfIcon from 'Guide/Icons/filetypes/32/pdf.svg';
import DocIcon from 'Guide/Icons/filetypes/32/doc.svg';
import XlsIcon from 'Guide/Icons/filetypes/32/xls.svg';
import PptIcon from 'Guide/Icons/filetypes/32/ppt.svg';
import FileIcon from 'Guide/Icons/filetypes/32/blank.svg';
import { More } from '@core-ds/icons/16';
import GeneralTooltip from 'Guide/Schematics/ui/generic/tooltip';
import { Toggle } from 'Guide/Schematics/logic/toggleable';

type DisplayDocument = {
   url: string;
   metadataUrl: string;
   thumbnailUrl: string;
   downloadUrl: string;
   type: string;
   title: string;
};

type DocumentsProps = {
   documents: DisplayDocument[];
   view: CellViewType;
};

enum CellViewType {
   List = 'list',
   Gallery = 'gallery',
}

type CellProps = {
   view: CellViewType;
};

const getIcon = filetype => {
   switch (filetype) {
      case 'word':
         return DocIcon;
      case 'excel':
         return XlsIcon;
      case 'powerpoint':
         return PptIcon;
      case 'pdf':
         return PdfIcon;
      default:
         return FileIcon;
   }
};

const MOBILE = `@media only screen and (max-width: ${breakpoint.sm})`;

const Cell = styled.div`
   display: flex;
   transition: background 0.1s;

   &:hover {
      text-decoration: none;
   }
`;

const cellTextStyle = css`
   font: normal bold ${fontSize.lg} / ${lineHeight.tight} Lato, ${fontFamily.monoSystem};
   color: ${color.gray[800]};
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   margin: 0;
`;

const ListCellText = styled.a`
   ${cellTextStyle}
   padding: 0 ${space[3]};
   flex-grow: 1;
   &:visited {
      color: ${color.gray[800]};
   }
`;

const MoreIconWrapper = styled.button`
   border: none;
   line-height: 0;
   flex-shrink: 0;
   display: flex;
   align-items: center;
   cursor: pointer;
`;

const ListMoreIconWrapper = styled(MoreIconWrapper)`
   background-color: transparent;
   height: 100%;
   color: ${color.gray[300]};
   transition: color 0.1s;
`;

const ListCell = styled(Cell)`
   flex: 0 1 50%;
   min-width: 0;

   padding: ${space[3]} ${space[2]};
   display: flex;
   align-items: center;

   &:hover {
      background-color: ${color.blue[50]};
      ${ListCellText} {
         text-decoration: none;
      }
      ${ListMoreIconWrapper} svg {
         color: ${color.gray[800]};
      }
   }

   ${MOBILE} {
      flex-basis: 100%;
   }
`;

const GalleryCellText = styled.span`
   ${cellTextStyle}
`;

const GalleryCellDiv = styled.div`
   position: absolute;
   bottom: 0;
   width: 100%;
   background-color: rgba(229, 231, 235, 0.95);
   padding: ${space[4]} ${space[5]};
   z-index: 1;
   transition: background-color 0.1s;
   border-radius: 0px 0px ${borderRadius['md']} ${borderRadius['md']};
`;

const GalleryMoreIconWrapper = styled(MoreIconWrapper)`
   justify-content: center;
   position: absolute;
   top: ${space[2]};
   right: ${space[2]};
   width: ${space[5]};
   height: ${space[5]};
   z-index: 1;
   border-radius: ${borderRadius['md']};
   background-color: ${color.white};
   visibility: hidden;
   transition: visibility 0.1s;
   padding: 0px;
`;

const radiusCss = css`
   border-radius: ${borderRadius.md};
`;

const hoverCss = css`
   .image-link::after {
      background-color: ${color.blue[200]};
   }
   ${GalleryCellDiv} {
      background-color: ${color.blue[50]};
   }
   ${GalleryMoreIconWrapper} {
      visibility: visible;
   }
`;

const GalleryCell = styled(Cell)<{ hovered: boolean }>`
   position: relative;
   flex-basis: calc(25% - ${space[5]});
   flex-direction: column-reverse;
   margin: 0 0 ${space[5]} ${space[5]};
   min-width: 0;
   height: 200px;
   box-shadow: ${shadow[1]};
   ${radiusCss}
   background-clip: border-box;

   .image-link {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
   }

   &:hover,
   &:focus {
      ${hoverCss}
   }

   ${props => (props.hovered ? hoverCss : null)}

   .image-link:hover {
      text-decoration: none;
   }

   ${MOBILE} {
      flex-basis: calc(100% - ${space[5]});
   }
`;

const GalleryFileIconImage = styled.svg`
   position: absolute;
   top: -21px;
   left: ${space[5]};
`;

const GalleryCellImage = styled.img`
   position: absolute;
   top: 0;
   min-width: 100%;
   ${radiusCss}

   ${MOBILE} {
      width: auto;
      margin: auto;
      left: 0;
      right: 0;
   }
`;

const Container = styled.div<CellProps>`
   display: flex;
   flex-wrap: wrap;

   ${props => props.view === CellViewType.Gallery && `margin-left: -${space[5]};`}
`;

const DropdownText = styled.a`
   font: normal normal ${fontSize.md} / ${lineHeight.tight} Lato, ${fontFamily.sansSystem};
   color: ${color.gray[800]};
   padding: ${space[3]} ${space[4]};

   &:hover {
      background-color: ${color.gray[50]};
      text-decoration: none;
      border-radius: ${borderRadius['md']};
   }

   &:visited {
      color: ${color.gray[800]};
   }
`;

const Dropdown = styled(GeneralTooltip)`
   padding: 0;
`;

const DropdownContentsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   text-align: left;
`;

const DropdownContent = ({ document }: { document: DisplayDocument }) => {
   return (
      <DropdownContentsWrapper>
         <DropdownText href={document.url}>{_js('View')}</DropdownText>
         <DropdownText href={document.metadataUrl}>{_js('History')}</DropdownText>
         <DropdownText role="button" download href={document.downloadUrl}>
            {_js('Download')}
         </DropdownText>
      </DropdownContentsWrapper>
   );
};

const IconLink = styled.a`
   flex-shrink: 0;
`;

const ListDocument = ({ document }: { document: DisplayDocument }) => {
   const Icon = getIcon(document.type);
   return (
      <ListCell>
         <IconLink href={document.url}>
            <Icon />
         </IconLink>
         <ListCellText href={document.url}>{document.title}</ListCellText>
         <Dropdown
            interactive={true}
            placement={'bottom'}
            content={<DropdownContent document={document} />}
         >
            <ListMoreIconWrapper>
               <More />
            </ListMoreIconWrapper>
         </Dropdown>
      </ListCell>
   );
};

const GalleryDocument = ({ document }: { document: DisplayDocument }) => {
   const Icon = props => {
      const I = getIcon(document.type);
      return <I {...props} />;
   };

   const popupToggle = Toggle();

   return (
      <GalleryCell hovered={popupToggle.isShown}>
         <a className="image-link" href={document.url}>
            <GalleryCellImage src={document.thumbnailUrl} alt={document.title} />
            <GalleryCellDiv>
               <GalleryFileIconImage as={Icon} />
               <GalleryCellText>{document.title}</GalleryCellText>
            </GalleryCellDiv>
         </a>
         <Dropdown
            interactive={true}
            placement={'bottom'}
            appendTo={'parent'}
            content={<DropdownContent document={document} />}
            onShown={popupToggle.show}
            onHidden={popupToggle.hide}
         >
            <GalleryMoreIconWrapper>
               <More />
            </GalleryMoreIconWrapper>
         </Dropdown>
      </GalleryCell>
   );
};

const Documents = ({ documents, view }: DocumentsProps) => {
   return (
      <Container view={view}>
         {documents.map((document: DisplayDocument) =>
            view === CellViewType.List ? (
               <ListDocument key={document.url} document={document} />
            ) : (
               <GalleryDocument key={document.url} document={document} />
            )
         )}
      </Container>
   );
};

export { DisplayDocument, CellViewType };
export default Documents;
