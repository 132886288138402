import * as React from 'react';
import { useState } from 'react';

const noop = () => {};

const ToggleLink = React.forwardRef(({ toggle, onClick, className, children }, ref) => {
   const originalOnClick = onClick || noop;
   const wrappedClick = () => {
      toggle.flip();

      return arguments ? originalOnClick(...arguments) : originalOnClick();
   };

   return (
      <a href="javascript:;" className={className} onClick={wrappedClick} ref={ref}>
         {children}
      </a>
   );
});

const ToggledComponent = ({ toggle, className, children }) => {
   return toggle.isShown && <div className={className}>{children}</div>;
};

const ToggledContainer = ({ toggle, children }) => {
   return toggle.isShown && children;
};

const Toggle = show => {
   const initialState = show || false;
   const [isShown, setIsShown] = useState(initialState);

   return React.useMemo(() => {
      return {
         isShown,
         hide: () => setIsShown(false),
         show: () => setIsShown(true),
         flip: () => setIsShown(!isShown),
      };
   }, [isShown]);
};

const NoOpToggle = {
   isShown: false,
   hide: noop,
   show: noop,
   flip: noop,
};

export { ToggleLink, ToggledComponent, Toggle, ToggledContainer, NoOpToggle };
