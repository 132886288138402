import * as React from 'react';
import { useEffect, useCallback } from 'react';
import initializeComponent from 'Shared/react-initialize';
import { color, space } from '@dozuki/web-js/primitives';
import { ListBullet, Grid } from '@core-ds/icons/16';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import Documents, { DisplayDocument, CellViewType } from './documents';

type ViewButtonProps = {
   selected: boolean;
};

const Header = styled.h2`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const ViewButton = styled.button<ViewButtonProps>`
   cursor: pointer;
   border: none;
   padding: 0;
   color: ${props => (props.selected ? color.gray[800] : color.gray[400])};
   background-color: transparent;
   transition: color 0.1s;

   &:first-child {
      margin-right: ${space[4]};
   }

   &:hover,
   &:focus {
      outline: none;
      color: ${color.gray[600]};
   }

   &:active {
      color: ${color.gray[800]};
   }
`;

const cookies = new Cookies();
const DOCUMENT_VIEW_KEY = 'documentView';
const DOCUMENT_VIEW_SWITCH = 12;

const initialView = numDocuments => {
   const documentView = cookies.get(DOCUMENT_VIEW_KEY);
   return (
      documentView ||
      ((numDocuments <= DOCUMENT_VIEW_SWITCH
         ? CellViewType.Gallery
         : CellViewType.List) as CellViewType)
   );
};

const DocumentsSection = ({ documents }: { documents: DisplayDocument[] }) => {
   const [view, setView] = React.useState(initialView(documents.length));

   const setViewAndCookie = useCallback(
      view => {
         setView(view);
         cookies.set(DOCUMENT_VIEW_KEY, view);
      },
      [setView]
   );

   const sectionName = _js('Documents');
   return (
      <div>
         <Header id={`Section_${sectionName}`} className="js-dynamic-toc-section">
            {sectionName}
            <span>
               <ViewButton
                  selected={view === CellViewType.List}
                  onClick={() => setViewAndCookie(CellViewType.List)}
                  title={_js('List View')}
               >
                  <ListBullet />
               </ViewButton>
               <ViewButton
                  selected={view === CellViewType.Gallery}
                  onClick={() => setViewAndCookie(CellViewType.Gallery)}
                  title={_js('Gallery View')}
               >
                  <Grid />
               </ViewButton>
            </span>
         </Header>
         <Documents documents={documents} view={view} />
      </div>
   );
};

initializeComponent('DocumentsSection', DocumentsSection);
