import * as React from 'react';
import { shadow, space } from '@dozuki/web-js/primitives';
import styled from 'styled-components';
import Tippy from '@tippy.js/react';

const ToolTip = styled(Tippy)`
   box-shadow: ${shadow[3]};
   color: black;
   background-color: white;
   border-radius: 4px;

   &[x-placement^='top'] {
      .tippy-arrow {
         border-top: 8px solid white;
         filter: drop-shadow(4px 4px 4px rgba(17, 22, 26, 0.2));
      }
   }

   &[x-placement^='bottom'] {
      .tippy-arrow {
         border-bottom: 8px solid white;
         filter: drop-shadow(4px -4px 4px rgba(17, 22, 26, 0.2));
      }
   }
`;

const VerticalContentsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: left;
   margin: ${space[3]} ${space[4]};
`;

const GeneralTooltip = props => {
   return (
      <ToolTip
         {...props}
         trigger="click"
         duration={100}
         inertia={true}
         animateFill={false}
         arrow={true}
         flipOnUpdate={true}
         animation="scale"
         boundary="window"
      >
         {props.children}
      </ToolTip>
   );
};

export default React.memo(GeneralTooltip);
export { VerticalContentsWrapper };
